// We need to import the CSS so that webpack will load it.
// The MiniCssExtractPlugin is used to separate it out into
// its own CSS file.
import '../css/app.scss';
import 'phoenix_html';
import { Socket } from 'phoenix';
import { LiveSocket } from 'phoenix_live_view';
import initMenuToggle from './menu_toggle.js';
import campusSlideshow from './campus_slideshow';
import closeFlashMessages from './close_flash_messages.js';
import checkRadioButton from './live_view_hooks/check_radio_button.js';
import toggleTextTruncation from './live_view_hooks/toggle_text_truncation';
import closeOnClick from './live_view_hooks/close_on_click.js';
import * as loadingIndicator from './loadingIndicator';
import { maybeInitProgramPdfPrint } from './programPdfPrint';
let csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute('content');
let Hooks = {
    checkRadioButton: checkRadioButton,
    toggleTextTruncation: toggleTextTruncation,
    closeOnClick: closeOnClick,
};
let liveSocket = new LiveSocket('/live', Socket, {
    params: { _csrf_token: csrfToken },
    hooks: Hooks,
});
liveSocket.disableDebug();
let clickableImage = document.querySelector('#main-background-clickable');
loadingIndicator.init();
liveSocket.connect();
// expose liveSocket on window for web console debug logs and latency simulation:
// >> liveSocket.enableDebug()
// >> liveSocket.enableLatencySim(1000)  // enabled for duration of browser session
// >> liveSocket.disableLatencySim()
window.liveSocket = liveSocket;
if (clickableImage) {
    clickableImage.addEventListener('click', (e) => {
        e.preventDefault();
        window.location.href = '#popup1';
    });
}
if (document.querySelector('.carousel__photo')) {
    campusSlideshow();
}
initMenuToggle();
closeFlashMessages();
maybeInitProgramPdfPrint();
