// As of 2020-10-15T16:58:03-07:00, LiveView does not reliably update an radio
// button's checked state when changing the 'checked' attribute. This hook, in
// conjunction with push_event/3, can be used as a work around.
export default {
    mounted() {
        this.handleEvent('check-radio-button', ({ id }) => {
            let radioButton = document.getElementById(id);
            radioButton.checked = true;
        });
    },
};
