/* type: e.g. `[ERROR] ` */
const doLogging = (type, ...items) => {
    console.log(type, ...items);
};
export const logger = {
    debug: (...items) => {
        doLogging('[DEBUG] ', ...items);
    },
    error: (...items) => {
        doLogging('[ERROR] ', ...items);
    },
    info: (...items) => {
        doLogging('[INFO] ', ...items);
    },
};
export const setElementVisible = (element, makeVisible) => {
    if (element) {
        if (makeVisible) {
            element.classList.remove('d-n');
        }
        else {
            element.classList.add('d-n');
        }
    }
};
export const scrollToTop = function () {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};
export const scrollToFirstError = () => {
    const firstError = document.querySelector('[phx-feedback-for]');
    if (firstError) {
        firstError.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
};
export const hideElements = (elements) => {
    elements.forEach((element) => {
        setElementVisible(element, false);
    });
};
export const showElements = (elements) => {
    elements.forEach((element) => {
        setElementVisible(element, true);
    });
};
