export default function initMenuToggle() {
    var _a, _b, _c, _d, _e;
    var hamburger = {
        navToggle: document.querySelector(".menu-toggle"),
        navToggleMobile: document.querySelector(".menu-toggle--mobile"),
        nav: document.querySelector(".navbar__list"),
        userMenuToggle: document.querySelector(".menu"),
        userMenuHolder: document.querySelector(".navbar__list-menu"),
        userMenuShevron: document.querySelector(".menu__chevron"),
        doToggle: function (e) {
            e.preventDefault();
            this.nav.classList.toggle("navbar__list--active");
        },
        doToggleUserDropdown: function (e) {
            var _a;
            e.preventDefault();
            this.userMenuHolder.classList.toggle("navbar__list-menu--showmenu");
            (_a = hamburger.userMenuShevron) === null || _a === void 0 ? void 0 : _a.classList.toggle("fa-chevron-down");
        },
    };
    (_a = hamburger.navToggle) === null || _a === void 0 ? void 0 : _a.addEventListener("click", function (e) {
        hamburger.doToggle(e);
    });
    (_b = hamburger.navToggleMobile) === null || _b === void 0 ? void 0 : _b.addEventListener("click", function (e) {
        hamburger.doToggle(e);
    });
    (_c = hamburger.userMenuToggle) === null || _c === void 0 ? void 0 : _c.addEventListener("click", function (e) {
        hamburger.doToggleUserDropdown(e);
    });
    (_d = hamburger.userMenuToggle) === null || _d === void 0 ? void 0 : _d.addEventListener("mouseenter", function (e) {
        hamburger.doToggleUserDropdown(e);
    });
    (_e = hamburger.userMenuHolder) === null || _e === void 0 ? void 0 : _e.addEventListener("mouseleave", function (e) {
        hamburger.doToggleUserDropdown(e);
    });
}
