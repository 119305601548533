import { hideElements, showElements } from './util';
const getElementsToHide = () => {
    return Array.from(document.querySelectorAll('.js_hide_from_print'));
};
const handlePrintBtnClick = (e) => {
    e.preventDefault();
    const elementsToHideFromPrint = getElementsToHide();
    hideElements(elementsToHideFromPrint);
    window.addEventListener('afterprint', (e) => {
        showElements(elementsToHideFromPrint);
    });
    window.print();
};
export const maybeInitProgramPdfPrint = () => {
    const trigger = document.getElementById('js_print_program_info_trigger');
    if (trigger) {
        trigger.addEventListener('click', handlePrintBtnClick);
    }
};
