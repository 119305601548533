export default {
    mounted() {
        const [text_paragraph] = this.el.getElementsByClassName("truncated-text__paragraph");
        const [label_expand] = this.el.getElementsByClassName("truncated-text__label-expand");
        const [label_shrink] = this.el.getElementsByClassName("truncated-text__label-shrink");
        const text_is_overflown = () => text_paragraph.scrollHeight > text_paragraph.clientHeight;
        if (text_is_overflown()) {
            label_expand.classList.remove("d-n");
        }
        truncateText(text_paragraph, label_expand, label_shrink);
        function truncateText(text_paragraph, label_expand, label_shrink) {
            const hidden_label_class = "d-n";
            const paragraph_no_height_limit_class = "truncated-text__paragraph--no-max-height";
            if (text_paragraph) {
                label_expand.addEventListener('click', () => {
                    toggle_truncate_text();
                });
                label_shrink.addEventListener('click', () => {
                    toggle_truncate_text();
                });
            }
            const toggle_truncate_text_label = () => {
                if (text_paragraph.classList.contains(paragraph_no_height_limit_class)) {
                    display_readless_label();
                }
                else {
                    display_readmore_label();
                }
            };
            const toggle_truncate_text = () => {
                text_paragraph.classList.toggle(paragraph_no_height_limit_class);
                toggle_truncate_text_label(text_paragraph);
            };
            const display_readmore_label = () => {
                label_shrink.classList.add(hidden_label_class);
                label_expand.classList.remove(hidden_label_class);
            };
            const display_readless_label = () => {
                label_expand.classList.add(hidden_label_class);
                label_shrink.classList.remove(hidden_label_class);
            };
        }
    }
};
