export default function closeFlashMessages() {
    const flashMessages = document.querySelectorAll(".flash");
    flashMessages.forEach((message) => {
        const closeIcon = message.querySelector(".flash__close-icon");
        closeIcon &&
            closeIcon.addEventListener("click", (e) => {
                e.preventDefault();
                message.classList.add("flash--closed");
            });
    });
}
