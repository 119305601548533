import { logger, setElementVisible } from './util';
const shouldDelayLoading = () => {
    try {
        return Boolean(document.querySelector('[data-delay_loading_indicator="yes"]'));
    }
    catch (e) { }
    return false;
};
export const init = () => {
    // Show loading spinner on websocket loading and hide when loading is completed
    const delayLoading = shouldDelayLoading();
    const loadingIcon = document.querySelector('.loading-page');
    if (!loadingIcon) {
        logger.error('loadingIcon not found');
        return;
    }
    let isLoading = false;
    window.addEventListener('phx:page-loading-start', (event) => {
        isLoading = true;
        if (delayLoading) {
            setTimeout(() => {
                if (isLoading) {
                    setElementVisible(loadingIcon, true);
                }
            }, 1000);
        }
        else {
            setElementVisible(loadingIcon, true);
        }
    });
    window.addEventListener('phx:page-loading-stop', (event) => {
        isLoading = false;
        setElementVisible(loadingIcon, false);
    });
};
